import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/users`, { params: queryParams })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        fetchUser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/user/${id}/edit`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                
                formData.append("photo", userData.photo);
                Object.entries(userData).map(item => {
                    if (item[0] !== "photo") {
                        if (item[0] === 'customerId'){
                            formData.append(item[0], item[1].id);
                        } else {
                            formData.append(item[0], item[1]);
                        }
                    }
                });
       
                axios
                .post(`${process.env.VUE_APP_HOST}/api/dashboard/user/create`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        updateUser (ctx, { id, userData }) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                
                formData.append('accessId', userData.accessId);
                formData.append('names', userData.access.names);
                formData.append('surnames', userData.access.surnames);
                formData.append("photo", userData.photo);
                formData.append("changePhoto", userData.changePhoto);
                formData.append("initPhoto", userData.initPhoto);
                formData.append("email", userData.access.email);
                formData.append("phone", userData.access.phone);
                formData.append("password", userData.password);
                formData.append("documentNumber", userData.documentNumber);
                formData.append("customerId", userData.customer.id);
                formData.append("position", userData.position);
        
                axios
                .put(`${process.env.VUE_APP_HOST}/api/dashboard/user/${id}/update`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        deleteUser (ctx, { id }){
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/user/${id}/delete`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        }
    },
}
